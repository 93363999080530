body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}

.hr-light{
	height:1;
	background-color:#000000;
	width:100%
}

.bigBar{
    height : 70px;
    background-color:#f8f9fa;
    width:100%;
    border-bottom: 1px solid #c0c0c0;
}

.frameLight{
    border: 1px solid #FBFBFB;
}

.smallBar{
    height : 45px;
    background-color:#f8f9fa;
    width:100%;
    border-bottom: 1px solid #c0c0c0;
}

a.navlink:link, a.navlink:visited {
	color: #7c7c7d;
}

a.navlink:hover, a.navlink:active {
    color: #000000;
}

a.navlink {
    text-decoration: none;
}
.rounded{
    border-radius: 50px;
}

.main-flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom : 1px solid #8b9dc3;
    padding: 10px;
    margin: 0px;
    align-items: center;
}

.marks-container {
    box-shadow: 0px 2px 10px #999999;
    border-radius: 0px;
    width: 100%;
    margin-right: 0px;
}

.right-flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.upper-flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom : 1px solid #e1e1e1;
}

.bottom-flex-container {
    display: flex;
    flex-direction: row;
}

.date-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}

.main-text {
  font-weight: bold;
}


.marks-grid-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.chart-grid-container {
    display: flex;
    width: 100%;
    flex-direction: column;;
    justify-content: flex-start;
    align-items: flex-start;
}

.chart-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-shadow: 0px 2px 10px #999999;
    border-radius: 0px;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    padding:0px;
}

.chart-container-mt3 {
  margin-top: 25px;
}
.w-100{
  width: 100%;
}

.icon-container {
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    /*background-color: lightseagreen ;*/
    background-color: #008896;
    justify-content: center;
    align-items: center;
    min-width: 42px;
    min-height: 42px;
    padding : 5px;
    margin-right : 15px;
  }


.icon-style {
    justify-content: center;
    align-items: center;
    text-align:center; 
    color: white; 
    font-size : 20px;
    font-weight: bold;
}

.default-bg {
    //background-color: #8b9dc3;
    background-color: #0c99c1;
}

.orange-bg {
  background-color: #ffa851;
}

.red-bg {
  //background-color: #cc0000;
  background-color: #ef3361;
}

.green-bg {
  //background-color: #388e3c;
  background-color: #00b7a8;
}

.test-frame{
  border: 1px solid red;
}

.test-green{
  border: 1px solid green;
}

.prewrap {
    white-space: pre-wrap;
}

.top-border {
    border-top : 1px solid #e1e1e1;
    margin-top: 2px;
}

.centered-container {
  display: flex;  
  justify-content: center;
  align-items: center;    
}

.centered-container-row {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  align-items: center;    
}

.centered-end-container {
  display: flex;  
  justify-content: center;
  align-items: flex-end;    
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ex2 {
    max-width: 450px;
}

.frame{	
	border: 1px solid #808080; 
}

.footer-fixed-login{
  color: #fff;
  //position: fixed;
  //bottom: 0;
  width: 100% !important;
  height: 100px !important ;
  background: #000;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
}

.footer-fixed-login-mob{
  color: #fff;
  //position: fixed;
  //bottom: 0;
  width: 100% !important;
  height: 50px !important ;
  background: #000;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
}
.loader {
	border: 16px solid #009688;
  	position: absolute;
	left: 50%;
	top: 60%;
	border-top: 16px solid blue;
	z-index: 10000;
	width: 100px;
	height: 100px;
	margin: -75px 0 0 -75px;
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #009688;
	width: 100px;
	height: 100px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10001;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 10001; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.overlay-text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.small-tr {
   line-height: 12px;
   min-height: 12px;
   height: 12px;
}

.framed-box {
	border : 1px solid #e1e1e1;
}

.footer-fixed{
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100% !important;
  min-height: 40px !important ;
  background: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.footer{
  color: #fff;
  width: 100% !important;
  height: 40px !important ;
  background: #000;
}

.icon-left-margin {
	margin-left: -10px;
}

.small-tr {
   line-height: 12px;
   min-height: 12px;
   height: 12px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}



.mark-icon-container {
  display: flex;
  width: 17px;
  height: 17px;
  border-radius: 0%;
  /*background-color: lightseagreen ;*/  	
  justify-content: center;
  align-items: center;
  min-width: 17px;
  min-height: 17px;
  padding : 5px;
  margin : 2px;
  margin-right: 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 10px #999999;
}

.mark-icon-style {
  justify-content: center;
  align-items: center;
  text-align:center; 
  color: white; 
  font-size : 17px;
}

.mark-desc-icon-container {
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #999999;  
  justify-content: center;
  align-items: center;  
  padding : 2px;
  margin : 2px;
  margin-right: 10px;
}

.mark-desc-icon-style {
  justify-content: center;
  align-items: center;
  text-align:center; 
  color: white; 
  font-size : 15px;
}

.ocjena-vladanje{
	font-weight: bold;
	color:  white;
	padding: 5px;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: 5px;
	box-shadow: 0px 2px 10px #999999;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

.upper-flex-container-tick {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom : 1px solid #bbb;
}

.main-flex-notif-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px;
    align-items: center;
    box-shadow: 0px 2px 10px #999999;
    margin-bottom: 25px;
}

.main-flex-notif-container:hover{
	background-color: rgba(0,0,0,0.05);
	transition: 0.1s;
}
.main-text-notif {
  font-weight: bold;
  font-size: 20px;
  margin : 5px;
}
.mark-details-header {
  	display: flex;
  	width: 100%;
  	flex-direction: row;
  	border-bottom : 1px solid #e1e1e1;
  	border-radius: 0px;
  	margin-top: 10px;
  	margin-bottom: 10px;
  	justify-content: space-between;
  	align-items: center;
  	padding-bottom: 10px;
}

.mark-details-subject {
  	display: flex;
  	width: 100%;
  	flex-direction: row;	
    justify-content: center;
  	align-items: center;
  	padding:0px;
}

.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

.admin-notif-header {
  	display: flex;
  	width: 100%;
  	flex-direction: row;
  	border-bottom : 1px solid #e1e1e1;
  	border-radius: 0px;
  	margin-top: 10px;
  	margin-bottom: 10px;
  	justify-content: space-between;
  	align-items: center;
  	padding-bottom: 10px;
}
.admin-toolbal-header {
  	display: flex;
  	width: 100%;
  	flex-direction: row;
  	border-bottom : 1px solid #e1e1e1;
  	border-radius: 0px;
  	justify-content: flex-end;
  	align-items: center;
  	padding-bottom: 10px;
}

.admin-footer{
	color: #fff;
  bottom: 0;
  width: 100% !important;
  min-height: 50px !important ;
  background: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  }

  .admin-root{
    min-height: 100vh; 
    width : 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content : center;
    padding : 0px;
    margin: 0px;
}

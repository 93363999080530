.small-tr {
   line-height: 12px;
   min-height: 12px;
   height: 12px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
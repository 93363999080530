.small-tr {
   line-height: 12px;
   min-height: 12px;
   height: 12px;
}

.framed-box {
	border : 1px solid #e1e1e1;
}

.footer-fixed{
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100% !important;
  min-height: 40px !important ;
  background: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.footer{
  color: #fff;
  width: 100% !important;
  height: 40px !important ;
  background: #000;
}

.icon-left-margin {
	margin-left: -10px;
}
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}

.hr-light{
	height:1;
	background-color:#000000;
	width:100%
}

.bigBar{
    height : 70px;
    background-color:#f8f9fa;
    width:100%;
    border-bottom: 1px solid #c0c0c0;
}

.frameLight{
    border: 1px solid #FBFBFB;
}

.smallBar{
    height : 45px;
    background-color:#f8f9fa;
    width:100%;
    border-bottom: 1px solid #c0c0c0;
}

a.navlink:link, a.navlink:visited {
	color: #7c7c7d;
}

a.navlink:hover, a.navlink:active {
    color: #000000;
}

a.navlink {
    text-decoration: none;
}
.rounded{
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.main-flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom : 1px solid #8b9dc3;
    padding: 10px;
    margin: 0px;
    align-items: center;
}

.marks-container {
    box-shadow: 0px 2px 10px #999999;
    border-radius: 0px;
    width: 100%;
    margin-right: 0px;
}

.right-flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.upper-flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom : 1px solid #e1e1e1;
}

.bottom-flex-container {
    display: flex;
    flex-direction: row;
}

.date-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}

.main-text {
  font-weight: bold;
}


.marks-grid-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.chart-grid-container {
    display: flex;
    width: 100%;
    flex-direction: column;;
    justify-content: flex-start;
    align-items: flex-start;
}

.chart-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-shadow: 0px 2px 10px #999999;
    border-radius: 0px;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    padding:0px;
}

.chart-container-mt3 {
  margin-top: 25px;
}
.w-100{
  width: 100%;
}

.icon-container {
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    /*background-color: lightseagreen ;*/
    background-color: #008896;
    justify-content: center;
    align-items: center;
    min-width: 42px;
    min-height: 42px;
    padding : 5px;
    margin-right : 15px;
  }


.icon-style {
    justify-content: center;
    align-items: center;
    text-align:center; 
    color: white; 
    font-size : 20px;
    font-weight: bold;
}

.default-bg {
    //background-color: #8b9dc3;
    background-color: #0c99c1;
}

.orange-bg {
  background-color: #ffa851;
}

.red-bg {
  //background-color: #cc0000;
  background-color: #ef3361;
}

.green-bg {
  //background-color: #388e3c;
  background-color: #00b7a8;
}

.test-frame{
  border: 1px solid red;
}

.test-green{
  border: 1px solid green;
}

.prewrap {
    white-space: pre-wrap;
}

.top-border {
    border-top : 1px solid #e1e1e1;
    margin-top: 2px;
}

.centered-container {
  display: flex;  
  justify-content: center;
  align-items: center;    
}

.centered-container-row {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  align-items: center;    
}

.centered-end-container {
  display: flex;  
  justify-content: center;
  align-items: flex-end;    
}


.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

.admin-notif-header {
  	display: flex;
  	width: 100%;
  	flex-direction: row;
  	border-bottom : 1px solid #e1e1e1;
  	border-radius: 0px;
  	margin-top: 10px;
  	margin-bottom: 10px;
  	justify-content: space-between;
  	align-items: center;
  	padding-bottom: 10px;
}
.admin-toolbal-header {
  	display: flex;
  	width: 100%;
  	flex-direction: row;
  	border-bottom : 1px solid #e1e1e1;
  	border-radius: 0px;
  	justify-content: flex-end;
  	align-items: center;
  	padding-bottom: 10px;
}

.admin-footer{
	color: #fff;
  bottom: 0;
  width: 100% !important;
  min-height: 50px !important ;
  background: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  }

  .admin-root{
    min-height: 100vh; 
    width : 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content : center;
    padding : 0px;
    margin: 0px;
}
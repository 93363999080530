.mark-details-header {
  	display: flex;
  	width: 100%;
  	flex-direction: row;
  	border-bottom : 1px solid #e1e1e1;
  	border-radius: 0px;
  	margin-top: 10px;
  	margin-bottom: 10px;
  	justify-content: space-between;
  	align-items: center;
  	padding-bottom: 10px;
}

.mark-details-subject {
  	display: flex;
  	width: 100%;
  	flex-direction: row;	
    justify-content: center;
  	align-items: center;
  	padding:0px;
}

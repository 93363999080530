.ex2 {
    max-width: 450px;
}

.frame{	
	border: 1px solid #808080; 
}

.footer-fixed-login{
  color: #fff;
  //position: fixed;
  //bottom: 0;
  width: 100% !important;
  height: 100px !important ;
  background: #000;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
}

.footer-fixed-login-mob{
  color: #fff;
  //position: fixed;
  //bottom: 0;
  width: 100% !important;
  height: 50px !important ;
  background: #000;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

.upper-flex-container-tick {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom : 1px solid #bbb;
}

.main-flex-notif-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px;
    align-items: center;
    box-shadow: 0px 2px 10px #999999;
    margin-bottom: 25px;
}

.main-flex-notif-container:hover{
	background-color: rgba(0,0,0,0.05);
	transition: 0.1s;
}
.main-text-notif {
  font-weight: bold;
  font-size: 20px;
  margin : 5px;
}
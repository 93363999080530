.mark-icon-container {
  display: flex;
  width: 17px;
  height: 17px;
  border-radius: 0%;
  /*background-color: lightseagreen ;*/  	
  justify-content: center;
  align-items: center;
  min-width: 17px;
  min-height: 17px;
  padding : 5px;
  margin : 2px;
  margin-right: 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 10px #999999;
}

.mark-icon-style {
  justify-content: center;
  align-items: center;
  text-align:center; 
  color: white; 
  font-size : 17px;
}

.mark-desc-icon-container {
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #999999;  
  justify-content: center;
  align-items: center;  
  padding : 2px;
  margin : 2px;
  margin-right: 10px;
}

.mark-desc-icon-style {
  justify-content: center;
  align-items: center;
  text-align:center; 
  color: white; 
  font-size : 15px;
}